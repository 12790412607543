import Sidebar from "./sidebar";
import Navbar from "./navbar";
import Footer from "./footer";


import React, { ReactNode } from "react";

interface Props {
    children?: ReactNode
}

const ThemeMaker = ({ children }: Props) => {
    return (
        <>
            <div id="wrapper">
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Navbar />
                         {children}
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ThemeMaker;